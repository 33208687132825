/**
 * 深拷贝函数
 * @param {*} source 需要深拷贝的对象
 * @returns 深拷贝后的对象
 */
export default function deepClone(source) {
  // 如果不是对象或数组，直接返回
  if (typeof source !== 'object' || source === null) {
    return source
  }

  // 根据source类型初始化结果
  const result = Array.isArray(source) ? [] : {}

  // 遍历source的所有属性
  for (const key in source) {
    // 仅拷贝source自身的属性
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      // 递归拷贝
      result[key] = deepClone(source[key])
    }
  }

  return result
}
